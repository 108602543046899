<template>
    <div>
        <div v-if="loading" class="d-flex align-items-center justify-content-center" style="height: 350px">
            <b-spinner v-for="variant in variants" :key="variant" :variant="variant" class="mr-1" type="grow" />
        </div>
        <div v-else>
            <div class="row justify-content-center my-2">
                <h1 v-if="participants.name">
                    Group <span class="text-light font-weight-bolder">{{ participants.name }}</span>
                </h1>
            </div>
            <div class="row justify-content-center">
                <b-card class="col-md-8">
                    <b-row class="justify-content-center my-3">
                        <b-col cols="4">
                            <b-row class="justify-content-center">
                                <h4>Choose a stage</h4>
                            </b-row>
                            <v-select v-model="stage_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="stagesOption" :clearable="false" label="name" @input="fetchGroupHandler(stage_id)" />
                        </b-col>
                        <b-col cols="4">
                            <b-row class="justify-content-center">
                                <h4>Choose a group</h4>
                            </b-row>
                            <v-select v-model="group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val" :options="groupOption" :clearable="false" label="name" @input="fetchGroupHandler(stage_id)" />
                        </b-col>
                    </b-row>
                </b-card>
            </div>
            <div class="row justify-content-center">
                <b-card v-for="item in participants.participants" :key="item.id" class="col-md-10">
                    <div class="row justify-content-between">
                        <div cols="6" class="text-center">
                            <!-- <b-row class="justify-content-center"> -->
                            <!-- <b-col cols="12" class="text-center"> -->
                            <b-avatar size="100" :src="item.user.thumbnail" :text="avatarText(item.user.name)" />
                            <h3>{{ item.user.name }}</h3>
                            <h5 class="text-secondary">{{ item.user.user_name }}</h5>
                            <b-badge variant="info" class="badge-glow"> {{ item.status }} </b-badge>
                            <!-- </b-col> -->
                            <!-- </b-row> -->
                        </div>
                        <div cols="3" class="text-center">
                            <b-row class="justify-content-center">
                                <b-col>
                                    <h2>Votes</h2>
                                    <h3 class="vote">{{ item.votes_count }}</h3>
                                </b-col>
                            </b-row>
                        </div>
                        <div cols="3" class="d-flex justify-content-center">
                            <b-button
                                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                variant="flat-info"
                                :to="{
                                    name: 'apps-contest-group-participants-detail',
                                    params: { participantId: item.id },
                                }"
                            >
                                <h2 class="text-info mt-5">Details</h2>
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                variant="flat-success"
                                @click="participantHandler(item)"
                            >
                                <h2 class="text-success">Participant</h2>
                            </b-button>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import { BSpinner, BFormCheckbox, BFormCheckboxGroup, BImg, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import PostRepository from "@/abstraction/repository/postRepository";
// import PostListFilters from "./PostListFilters.vue";
import userStoreModule from "../../user/userStoreModule";
import VideoPlayer from "@/views/components/video/VideoPlayer";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import useContestList from "../useContestList";
import ContestRepository from "@/abstraction/repository/contestRepository";
const contestRepository = new ContestRepository();

// repository
const postRepository = new PostRepository();
export default {
    directives: {
        Ripple,
    },
    components: {
        BSpinner,
        BFormCheckbox,
        BFormCheckboxGroup,
        // PostListFilters,
        BImg,
        VideoPlayer,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
    },

    setup() {
        onMounted(async () => {});
        const loading = ref(true);
        console.log("router", router.currentRoute.params);
        const variants = ref(["primary", "secondary", "danger", "warning", "success", "info", "light", "dark"]);
        const participants = ref();
        const stagesOption = ref();
        const groupOption = ref([]);
        const stage_id = ref();
        const group_id = ref();
        const { fetchGroupContest, storeCandidates, showContestGroup } = useContestList();
        const fetchStageList = () => {
            contestRepository.fetchStageContest(router.currentRoute.params.contestId).then((res) => {
                stagesOption.value = res.data;
            });
        };
        fetchStageList();
        const fetchGroupHandler = (id) => {
            fetchGroupContest(id).then((res) => {
                groupOption.value = res.value;
            });
        };
        showContestGroup(router.currentRoute.params.groupId).then((res) => {
            participants.value = res;
            loading.value = false;
            console.log("participants", participants.value);
        });
        const participantHandler = (dataItems) => {
            console.log(dataItems);
            if (stage_id.value && group_id.value.id) {
                let data = {
                    stage_id: stage_id.value,
                    group_id: group_id.value.id,
                    user_id: dataItems.user_id,
                    status: "participant",
                };
                if (dataItems.post) {
                    data.contest_post_id = dataItems.post.id;
                }
                if (dataItems.story) {
                    data.contest_story_id = dataItems.story.id;
                }
                console.log(data);
                storeCandidates(data, router.currentRoute.params.contestId).then((res) => {
                    console.log(res);
                });
            } else {
                context.root.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "ERROR",
                        icon: "BellIcon",
                        text: "Please select stage and group first !!",
                        variant: "danger",
                    },
                });
            }
        };
        return {
            participants,
            avatarText,
            variants,
            loading,
            stagesOption,
            groupOption,
            stage_id,
            group_id,
            fetchGroupHandler,
            participantHandler,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
.vote {
    margin-top: 50px;
    font-size: 50px;
    color: aqua;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
